import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <nav className="navbar navbar-expand-md navbar-light qlume-header">
      <Link className="navbar-brand" to="/">
        {" "}
        <img src={"/QluMe-logo.png"} alt="Qlumein logo" />
      </Link>
      {/* <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <img src={"/menu.png"} className="d-block" />
        </span>
      </button> */}
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <a
              className="nav-link"
              href="https://play.google.com/store/apps/details?id=com.qlume.app"
            >
              <img src={"/play-store.png"} alt="Play store logo" />
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://apps.apple.com/vn/app/qlume-social-media-connect/id1554809521"
            >
              <img src={"/apple-store.png"} alt="Apple store logo" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
