import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div className="footer">
      <div className="footer-img">
        <img src={"/green-q.svg"} alt="" />
      </div>
      <div className="footer-content text-center text-md-left d-flex flex-column flex-md-row">
        <div className="footer-first-column">
          <h4 className="footer-title">About us</h4>
          <p>
            We are a community of people who just want to enjoy life, meet new
            friends, hang out and have fun. QluMeIn was designed to allow you to
            choose what you want to do and when, then allow you to find the
            right group of friends to do it with. Live life to the fullest with
            QluMeIn
          </p>
        </div>
        {/* TODO: commented until we have social media accounts */}
        {/* <div className="footer-second-column">
          <div>
            <h4 className="contact-title">Contact with us</h4>
            <div className="d-flex align-items-center justify-content-center justify-content-md-end">
              <a className="social-link" href="./">
                <img src={"/facebook.png"} alt="Facebook logo" />
              </a>
              <a className="social-link mx-3" href="./">
                <img src={"/google.png"} alt="Google logo" />
              </a>
              <a className="social-link" href="./">
                <img src={"/instgram.png"} alt="Instagram logo" />
              </a>
            </div>
          </div>
        </div> */}
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col text-center">
            <Link className="m-2 pb-4" to="/">
              Home
            </Link>
            <Link className="m-2 pb-4" to="/privacy">
              Privacy
            </Link>
            <Link className="m-2 pb-4" to="/terms">
              Terms
            </Link>
            <Link className="m-2 pb-4" to="/contact-us">
              Contact us
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="copyright col">
            <p>
              © All Rights Reserved | Designed By{" "}
              <Link to="https://www.sifars.com/">Sifars</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
